import React from "react"
import Img from "gatsby-image"

const Fournisseur = ({item}) => {
    return (
        <div className="fournisseur">
            <h3>{item.post_title}</h3>
            { (item.acf.logo != null)? <Img fixed={item.acf.logo.localFile.childImageSharp.fixed}/> : '' }
            <div className="fournisseur__description">
                <div dangerouslySetInnerHTML={{__html: item.acf.description}} />
                <a href={item.acf.link.url} target={item.acf.link.target}>{item.acf.link.title}</a>
            </div>
        </div>
    )
}

export default Fournisseur