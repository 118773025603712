import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page_header"
import Fournisseur from "../components/fournisseur"

export const query = graphql`
  query($id: String!) {
    subCategory: wordpressPage(id: { eq: $id }) {
      id
      slug
      title
      acf {
        description
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        fournisseurs {
          post_title
          wordpress_id
          acf {
            description
            link {
              title
              url
              target
            }
            logo {
              localFile {
                childImageSharp {
                  fixed(width: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`

const SubCategory = ({ data }) => {
  const category = data.subCategory
  const fournisseurs = data.subCategory.acf.fournisseurs
  const meta = data.subCategory.yoast_meta

  const renderFournisseurs = fournisseurs => {
    if (fournisseurs === null) {
      return <div />
    }

    return fournisseurs.map(item => (
      <Fournisseur key={item.wordpress_id} item={item} />
    ))
  }

  return (
    <Layout>
      <SEO
        title={meta.yoast_wpseo_title}
        description={meta.yoast_wpseo_metadesc}
      />
      <PageHeader title={category.title} image={category.acf.banner_image} />
      <main className="content__container -category">
        <section
          className="category__description"
          dangerouslySetInnerHTML={{ __html: category.acf.description }}
        />
        <section className="category__fournisseurs">
          <h2>Les fournisseurs</h2>
          {renderFournisseurs(fournisseurs)}
        </section>
      </main>
    </Layout>
  )
}

export default SubCategory
